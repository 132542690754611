<template>
	<el-dialog title="编辑收款信息" v-model="centerDialogVisible" width="40%" center :close-on-click-modal = "false" @close='closecallinfo'>
		<div class="ucPanel" style="height: 668px;">
			<div class="ucPanel bgGrayBox">
				<div class="ucForm ucForm-lump auto-d3 nameLong" >
					<div class="ucFrom-row" style="margin-bottom: 20px;">
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同名称</label>
							<input name="wholesalePrice" type="text" v-model="cname" :disabled="true" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同编号</label>
							<input name="wholesalePrice" type="text" v-model="cno" :disabled="true" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">			
						<div class="ucForm-group">
							<label class="ucForm-label">合同签订部门</label>
							<input name="wholesalePrice" type="text" v-model="gName" :disabled="true" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">签订时间</label>
							<input name="wholesalePrice" type="text" v-model="signDate" :disabled="true" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同金额/元</label>
							<input name="wholesalePrice" type="number" v-model="famount" :disabled="true" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label" v-if="isshow">收款金额/元</label>
							<label class="ucForm-label" v-if="isshow2">付款金额/元</label>
							<input name="wholesalePrice" type="number" v-model="amount" :disabled="viewOnly" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label" v-if="isshow">收款时间</label>
							<label class="ucForm-label" v-if="isshow2">付款时间</label>
							<el-config-provider :locale="locale">
								<el-date-picker v-model="paydate" type="date" value-format="YYYY-MM-DD"></el-date-picker>
							</el-config-provider>
						</div>
					</div>
					<div class="ucForm-group-ad auto-d100">
						<div class="ucForm-group">
							<label class="ucForm-label">内容备注</label>
							<textarea name="wholesalePrice" type="text" v-model="mark" :disabled="viewOnly" class="ucForm-control"></textarea>
						</div>
					</div>
					</div>
					<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">		
						<div class="ucForm-group ucBtn-bg">
							<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
							<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button>
						</div>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>	
			
	</el-dialog>
</template>

<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				centerDialogVisible: false,
				id: this.$parent.editId,
				cno:this.$parent.cno,
				cname:this.$parent.cname,
				gName:this.$parent.gName,
				signDate:this.$parent.signDate,
				amount:this.$parent.amount,
				famount:this.$parent.famount,
				paydate:this.$parent.paydate,
				mark:this.$parent.mark,
				isshow:this.$parent.isshow,
				isshow2:this.$parent.isshow2,
				ctype:this.$parent.ctype,
				mod:this.$parent.infoMod,
				cid:this.$parent.cnox
			}
		},
		created() {
			this.centerDialogVisible = true;
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				if(this.amount==0||this.amount==null){
					this.$message({
						message:'请输入金额',
						type:'error'
					})
					return;
				}
				if(this.paydate==''||this.paydate==null){
					this.$message({
						message:'请选择时间',
						type:'error'
					})
					return;
				}
				req.cno=this.cno;
				req.amount=this.amount;
				req.paydate=this.paydate;
				req.mark=this.mark;
				req.ftype=this.ctype;
				req.cid=this.cid;
				if (this.mod == 'add') {
					req.cmd = "insertRevenueAmount";
				} else {
					req.id = this.id;
					req.cmd = "updateRevenueAmount";
				}
				req.name = self.name;
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message("操作成功");
					self.closecallinfo();
				}, null, self);
			},
			fetch: function() {
				var req = {};
				req.cmd = 'mng_conf_area_get';
				req.id = this.id;
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					var item = res.area;
					this.name = item.name;
				}, null, this);
			},
			closecallinfo: function() {
				this.centerDialogVisible = true;
				this.$emit('closecallinfo'); //通知父组件改变。
			}
		},
		mounted() {
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				// this.fetch();
			}
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
